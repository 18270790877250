import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from 'gatsby'

const SenderoPage = () => (
  <Layout>
    <nav class="navbar">
      <Link class="navbar-brand" to="/">Volver</Link>
    </nav>
    <SEO title="Sendero" />
    <div className="row justify-content-end">
      <div className="col-12 col-md-10">
        <img className="img-fluid" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-13.jpg" alt="Arbol" />
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-8">
        <h1 className="text-center mt-5">Acto Sendero</h1>
        <p>
          Transitar el estrecho camino del sendero es a su vez caminar el tiempo.
                    <br /><br /> “Hacer una milpa, es un ejemplo de escritura, se limpia el espacio, luego se pone la semilla para que nazca la vida, la palabra, la historia, el alimento del alma, la palabra.
                    <br /><br /> Escribir, según la representación del pensamiento maya, es limpiar para poner huella, pero no cualquier huella, es la de la planta del pie que camina, es reconocer que la madre tierra guarda la memoria, ella es quien se convierte
        en una hoja de papel en blanco para recibir la imagen del movimiento, del pie que la camina…” <span className="no-wrap">(Pedro Uc Be, 2016)</span>
          <br /><br /> Es el camino del sendero que se forma por el paso de animales y personas, es su caminar el que limpia el sendero y a su vez escribe, también lo limpia el viento y lo deja dispuesto para de nueva cuenta poner la huella.
                    <br /><br /> Los guardianes del monte también guardan la historia, el tiempo de la guerra, son testigos del trabajo de la milpa, de intercambios festivos, de los pasos resistentes. De los que para orar caminan, de los que para caminar
            resisten.
                </p>
      </div>
    </div>

    <video controls autoPlay loop className="img-fluid mt-4" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/sendero560.mp4"></video>
  </Layout >
)

export default SenderoPage
